import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import './tailwind.css';
import './App.css';

import Redirect from './Redirect';
import Home from './Home';
import VerifyEmail from './VerifyEmail';
import DashboardScreen from './DashboardScreen';
import PlaidScreen from './PlaidScreen';
import BankDisconnectScreen from './BankDisconnectScreen';
import FileUpload from './FileUpload';
import ThanksScreen from './ThanksScreen';
import NotFound from './NotFound';
import PlaidAccounts from './PlaidAccounts';
import UnsubscribeScreen from './UnsubscribeScreen';
import PricingScreen from './PricingScreen';
import ServicesScreen from './ServicesScreen';
import TechnologyScreen from './TechnologyScreen';
import StoryScreen from './StoryScreen';
import PayrollScreen from './PayrollScreen';
import SubscriptionScreen from './SubscriptionScreen';
import DebtConsolidate from './DebtConsolidateScreen';
import ManageAccountScreen from './ManageAccountScreen';
import AdminApp from './admin/App';
import SearchScreen from './Bank/Search';
import BankLoginScreen from './Bank/Login';
import BankLogin2FaScreen from './Bank/Login2Fa';
import BankAccountsScreen from './Bank/Accounts';

import { FaBars, FaCrown } from 'react-icons/fa';
// const PlaidScreen = React.lazy(() => import('./PlaidScreen'));

import 'flowbite';

function App() {
  // var initHasAccount = false;

  // const loginId = localStorage.getItem("flinks_loginId");
  // if (loginId !== undefined && loginId != null) {
  //   initHasAccount = true;
  //   setActiveComponent("Component4");
  // } else {
  //   initHasAccount = false;
  //   setActiveComponent("Component1");
  // }

  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [stripeStatus, setStripeStatus] = useState(false);
  const [showProButton, setShowProButton] = useState(false);
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
  // const [hasAccount, setHasAccount] = useState(initHasAccount);
  // const [submitCount, setSubmitCount] = useState(0);

  // const onMessageReceived = (e) => {
  //   if (e.data.step === 'REDIRECT' && 'loginId' in e.data && e.data.loginId !== undefined && submitCount === 0) {
  //     window.removeEventListener("message", onMessageReceived);

  //     localStorage.setItem("flinks_loginId", e.data.loginId);

  //     if (email !== null && email.length > 0 && e.data.loginId !== null && e.data.loginId.length > 0) {
  //       // setHasAccount(true);
  //       setSubmitCount(submitCount + 1);
  //       setActiveComponent("Component4");

  //       const formData = {
  //         email: email,
  //         loginId: e.data.loginId,
  //       };

  //       // Sending the form data to Firebase Functions
  //       fetch(process.env.REACT_APP_CANCELLY_API_URL + '/submit', {
  //         method: 'POST',
  //         headers: { "Content-type": "application/json" },
  //         body: JSON.stringify(formData)
  //       })
  //         .then((response) => response.json())
  //         .then((data) => {
  //           // Handle response from Firebase Functions
  //           console.log(data);
  //         })
  //         .catch((error) => {
  //           // Handle error
  //           console.error(error);
  //         });
  //     }

  //     // setHasAccount(true);
  //     setActiveComponent("Component4");
  //   }
  // };

  // window.addEventListener("message", onMessageReceived, true);

  // const [isLoadingIFrame, setIsLoadingIFrame] = useState(!initHasAccount);

  // const handleIframeLoad = () => {
  //   setIsLoadingIFrame(false);
  // };

  const handleButtonClick = () => {
    if ("vibrate" in navigator) {
      navigator.vibrate(100);
    }
  };

  useEffect(() => {
    const buttons = document.querySelectorAll('button');
    buttons.forEach(button => {
      button.addEventListener('click', handleButtonClick);
    });

    return () => {
      buttons.forEach(button => {
        button.removeEventListener('click', handleButtonClick);
      });
    }
  }, []);

  const scrollToIframe = () => {
    const container = document.getElementById('plaid-container');
    const iframe = container.querySelector('iframe');
    if (iframe) {
      console.log(iframe);
      const duration = 5;
      const { x, y } = iframe.getBoundingClientRect();

      console.log('x, y', x, y);

      const scrollStep = Math.PI / (duration / 15);
      let count = 0;
      let curPosition = {
        x: window.scrollX,
        y: window.scrollY
      };

      let animationRunning = true;

      const scrollAnimation = () => {
        if (!animationRunning) return;

        if (window.scrollX !== x || window.scrollY !== y) {
          window.scrollTo(curPosition.x + ((x - curPosition.x) / duration) * count,
            curPosition.y + ((y - curPosition.y) / duration) * count);
          count += 1;
          setTimeout(scrollAnimation, 50);
        } else {
          animationRunning = false;
        }
      };

      scrollAnimation();
    }
  };

  useEffect(() => {
    // let intercomOpts = {
    //   api_base: "https://api-iam.intercom.io",
    //   app_id: "dg72bxmh",
    //   name: user.name, // Full name
    //   email: user.email, // Email address
    //   created_at: user.createdAt // Signup date as a Unix timestamp
    // };

    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setEmail(user.email);

        if (user.metadata.creationTime === user.metadata.lastSignInTime) {
          window.gtag('event', 'conversion', { 'send_to': 'AW-11042834702/5pfBCNPvle8YEI6S0ZEp' });
        }

        const idTokenResult = await user.getIdTokenResult();
        const isAdminUser = idTokenResult.claims.admin;
        setIsAdmin(isAdminUser);

        const email = user.email;
        localStorage.setItem('email', email);
        setShowMenu(true);

        const token = await user.getIdToken();
        // console.log('User Token:', token);

        fetch(process.env.REACT_APP_CANCELLY_API_URL + "/stripe/status", {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email })
        })
          .then(res => res.json())
          .then(res => {
            if (res.status === "ok" && res.count > 0) {
              setStripeStatus(true);
              setShowProButton(false);
            } else {
              setStripeStatus(false);
              setShowProButton(true);
            }
          })
          .catch(err => {
            setStripeStatus(false);
            setShowProButton(true);
          });

        fetch(process.env.REACT_APP_CANCELLY_API_URL + "/auth/custom_token", {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        })
          .then(res => res.json())
          .then(res => {
            try {
              const sender = {
                action: 'auth_token',
                body: {
                  token: res.token,
                }
              };
              if (Object.hasOwn(chrome.runtime, 'sendMessage')) {
                chrome.runtime.sendMessage("bdehkmgfjppeagefkipigfmlnginebkd", sender); /* global chrome */
                chrome.runtime.sendMessage("jlmgalhiiblhpgkemdoajofnbmhkoplg", sender); /* global chrome */
              }
            } catch (err) {
              console.log('Error sending message to chrome extension', err);
            }
          })
          .catch(err => {
            console.error('Error occurred', err);
          });

        // intercomOpts = {
        //   ...intercomOpts,
        //   email: user.email,
        //   created_at: new Date(user.metadata.creationTime)
        // };
      } else {
        setIsAdmin(false);
        localStorage.removeItem('email');

        try {
          const sender = {
            action: 'auth_token'
          };
          chrome.runtime.sendMessage("bdehkmgfjppeagefkipigfmlnginebkd", sender); /* global chrome */
          chrome.runtime.sendMessage("jlmgalhiiblhpgkemdoajofnbmhkoplg", sender); /* global chrome */
        } catch (err) {
          console.log('Error sending message to chrome extension', err);
        }

        setShowMenu(false);
      }

      //window.Intercom("boot", intercomOpts);
    });
  }, []);

  useEffect(() => {
    const minSwipeDistance = 50;

    let touchStartX = 0;
    let touchEndX = 0;

    function handleTouchStart(event) {
      touchStartX = event.touches[0].clientX;
    }

    function handleTouchMove(event) {
      event.preventDefault();
    }

    function handleTouchEnd(event) {
      touchEndX = event.changedTouches[0].clientX;
      const swipeDistance = touchEndX - touchStartX;

      if (swipeDistance < -minSwipeDistance) {
        console.log("Swipe left detected!");
        setMenuOpen(false);
      } else if (swipeDistance > minSwipeDistance) {
        console.log("Swipe right detected!");
        setMenuOpen(true);
      }
    }

    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, []);

  useEffect(() => {
    if (showMenu) {
      const menu = document.getElementById('menu');

      const handleTransitionEnd = (event) => {
        if (!menuOpen && event.propertyName === 'left' && menu.style.left === '-200px') {
          menu.classList.add('hidden');
        }
      };

      menu.addEventListener('transitionend', handleTransitionEnd);

      return () => {
        menu.removeEventListener('transitionend', handleTransitionEnd);
      };
    }
  }, [showMenu]);

  const toggleMenu = (event) => {
    const buttonEle = ["A", "BUTTON"];
    let buttonElement = buttonEle.includes(event.target.tagName) ? event.target : event.currentTarget;
    if (buttonElement && buttonElement.innerText.startsWith('PRO')) {
      event.preventDefault();
      return false;
    } else {
      const menu = document.getElementById('menu');
      if (menu) {
        menu.classList.remove('hidden');
        setMenuOpen(!menuOpen);
      }
    }
  };

  const signOut = (event) => {
    event.preventDefault();
    const auth = getAuth();
    auth.signOut().then(() => {
      console.log("Signed Out!");
      window.localStorage.removeItem("admin");
      window.localStorage.removeItem("email");
      navigate("/");
    }).catch((err) => {
      console.error(err)
    });
  }

  const checkoutButton = (event) => {
    event.preventDefault();
    navigate('/pricing');
    return false;
  };

  // const [shouldShowFileUploader, setShouldShowFileUploader] = useState(false);

  const comingSoon = () => {
    alert('Coming Soon.. Feel free to reach out to us by our Live Chat')
  };

  return (
    <div className="App-Content lg:mt-2">
      {/* <img className="App" src="logo.png" alt="Cancelly" width="200" /> */}
      <div className="App-header pt-5 pb-0 pl-6 pr-5">
        <div class="flex">
          <div className="">
            <div class="flex">
              <div class="flex items-center pt-1">
                {showMenu && <div className="text-left">
                  <button className="App-button" onClick={toggleMenu}><FaBars /></button>
                </div>}
              </div>
              <div class="ml-2 flex flex-row text-white">
                {stripeStatus
                  ? <h1 className="App-logo font-bold text-[#FFD700] text-left select-none">
                    cancelly<FaCrown className="w-3" />
                  </h1>
                  : <h1 className="App-logo font-bold text-white text-left select-none">
                    <a href="/">cancelly</a>
                  </h1>}
                <div className="flex ml-4">
                  <a href="#" data-dropdown-toggle="services-dropdown" className="flex mx-2 select-none hover:underline">
                    services <svg class="w-2 h-2 ml-1 my-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
                    </svg></a>
                  {!showMenu && <a href="/pricing" className="mx-2 select-none hover:underline">pricing</a>}
                  <a href="/story" className="mx-2 select-none hover:underline">story</a>
                  {!showMenu && <a href="https://cancellyblog.wordpress.com/"
                    target="_blank"
                    className="mx-2 select-none hover:underline">blog</a>}
                  <div id="services-dropdown" class="z-10 min-w-[300px] hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                    <ul class="py-2 text-xl text-left text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                      <li>
                        <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white" onClick={comingSoon}>
                          <label for="helper-checkbox-1" class="font-medium text-gray-900 dark:text-gray-300">
                            <div>Debt Consolidation</div>
                            <p id="helper-checkbox-text-1" class="text-xs font-normal text-gray-500 dark:text-gray-300">
                              Link your bank accounts for an automatic debt consolidation.
                              <br /><br />
                              Pay off your debt faster.</p>
                          </label>
                        </a>
                      </li>
                      <li>
                        <a href="/automated-payroll" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                          <label for="helper-checkbox-1" class="font-medium text-gray-900 dark:text-gray-300">
                            <div>Automated Payroll</div>
                            <p id="helper-checkbox-text-1" class="text-xs font-normal text-gray-500 dark:text-gray-300">
                              Use our Payroll Sheet to do your sole-proprietor, small-business or an enterprise payroll with ease.
                              <br /><br />
                              For Self-Employed, Small Business Owner and Accountants</p>
                          </label>
                        </a>
                      </li>
                      <li>
                        <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white" onClick={comingSoon}>
                          <label for="helper-checkbox-1" class="font-medium text-gray-900 dark:text-gray-300">
                            <div>Tax Filing</div>
                            <p id="helper-checkbox-text-1" class="text-xs font-normal text-gray-500 dark:text-gray-300">
                              Let our experts file your annual personal or corporate taxes.
                              <br /><br />
                              For Students, Self-Employed, Small Businesses</p>
                          </label>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute right-0 mr-6">
            {showMenu && showProButton && <div className="text-right">
              <button onClick={checkoutButton} className="text-xl px-3 text-slate-600 bg-[#FFD700] shadow-xl active:shadow-inner font-['Kanit'] italic font-black">PRO<FaCrown className="inline pb-1.5 ml-1" /></button>
            </div>}
          </div>
        </div>
      </div>
      {showMenu ? <div>
        <div id="menu" className={`Menu ${menuOpen ? 'open' : ''} z-50 select-none`}>
          <ul className="Menu-list z-50">
            <li className="Menu-item z-50">
              <a href="/">Home</a>
            </li>
            <li className="Menu-item z-50">
              <a href="/connect">Add Bank Account</a>
            </li>
            <li className="Menu-item z-50">
              <a href="/debt-consolidate">Debt Consolidate</a>
            </li>
            <li className="Menu-item z-50">
              <a href="/manage">Manage Account</a>
            </li>
            {/* <li className="Menu-item z-50">
              <a href="/pricing">Category <FaCrown className="inline p-1 rounded bg-[#FFD700]" /></a>
            </li>
            <li className="Menu-item z-50">
              <a href="/pricing">Graphs <FaCrown className="inline p-1 rounded bg-[#FFD700]" /></a>
            </li> */}
            <li className="Menu-item z-50">
              <a href="https://cancellyblog.wordpress.com/" target="_blank">Blog</a>
            </li>
            <li className="Menu-item z-50">
              <a href="/pricing">Pricing</a>
            </li>
            {isAdmin &&
              <li className="Menu-item z-50">
                <a href="/admin">Admin</a>
              </li>
            }
            <li className="Menu-item z-50">
              <a href="#" onClick={signOut}>Sign Out</a>
            </li>
          </ul>
          <footer className="leading-8 text-black pb-5">
            <p className="text-xl mt-20 mb-4">Follow us on</p>
            <div className="flex flex-row text-center justify-around mx-4">
              <a href="https://www.instagram.com/cancelly.ca" target="_blank" rel="noreferrer">
                <img src="/assets/email/instagram.png" alt="Instagram" className="social-icon w-[32px] h-[32px]" />
              </a>
              <a href="https://www.facebook.com/cancelly" target="_blank" rel="noreferrer">
                <img src="/assets/email/facebook.png" alt="Facebook" className="social-icon w-[32px] h-[32px]" />
              </a>
              <a href="https://www.linkedin.com/company/cancelly/" target="_blank" rel="noreferrer">
                <img src="/assets/email/linkedin.png" alt="Facebook" className="social-icon w-[32px] h-[32px]" />
              </a>
              <a href="https://www.youtube.com/@cancelly-app" target="_blank" rel="noreferrer">
                <img src="/assets/email/youtube.png" alt="Facebook" className="social-icon w-[32px] h-[32px]" />
              </a>
            </div>
          </footer>
        </div>
      </div> : <div></div>}
      <div>
        <Routes>
          <Route exact path="/" element={<Redirect />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/verify" element={<VerifyEmail />} />
          <Route path="/thanks" element={<ThanksScreen />} />
          <Route path="/unsubscribe" element={<UnsubscribeScreen />} />
          <Route path="/pricing" element={<PricingScreen />} />
          <Route path="/services" element={<ServicesScreen />} />
          <Route path="/story" element={<StoryScreen />} />
          <Route path="/automated-payroll" element={<PayrollScreen />} />
          {showMenu && <>
            <Route path="/dashboard" element={<DashboardScreen />} />
            <Route path="/connect" element={<PlaidScreen />} />
            <Route path="/disconnect" element={<BankDisconnectScreen />} />
            <Route path="/accounts" element={<PlaidAccounts />} />
            <Route path="/csv" element={<FileUpload />} />
            <Route path="/subscriptions" element={<SubscriptionScreen />} />
            <Route path="/debt-consolidate" element={<DebtConsolidate />} />
            <Route path="/manage" element={<ManageAccountScreen />} />
            <Route exact path="/bank" element={<SearchScreen />} />
            <Route path="/bank/login" element={<BankLoginScreen />} />
            <Route path="/bank/login2fa" element={<BankLogin2FaScreen />} />
            <Route path="/bank/accounts" element={<BankAccountsScreen />} />
          </>}
          {isAdmin &&
            <Route path="/admin" element={<AdminApp />} />
          }
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
